const calendarOptions = [
  {
    color: 'info',
    label: 'Consulta Inicial',
    id: 1,
  },
  {
    color: 'gray',
    label: 'Consulta Regular',
    id: 5,
  },
  {
    color: 'info',
    label: 'Consulta Final',
    id: 6,
  },
  {
    color: 'warning',
    label: 'Testes Cognitivos',
    id: 2,
  },
  {
    color: 'pink',
    label: 'Aplicação do Jogo',
    id: 4,
  },
  {
    color: 'info',
    label: 'Jogo Teste',
    id: 3,
  },
  {
    color: 'success',
    label: 'Nenhuma aplicação',
    id: 7,
  },
]
// eslint-disable-next-line import/prefer-default-export
export const calendarUtils = {
  getOptions() {
    return calendarOptions
  },
  getById(id) {
    if (id) {
      return calendarOptions.find(option => option.id === id)
    }
    return null
  },
  getByLabel(label) {
    if (label) {
      return calendarOptions.find(option => option.label === label)
    }
    return null
  },
}
