var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-calendar overflow-hidden border"
  }, [_c('b-row', [_c('b-col', [_c('b-card', {
    staticClass: "header-marcar_consulta bg-gradient-primary "
  }, [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', [_c('h1', [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "CalendarIcon",
      "size": "30"
    }
  }), _vm._v(" Agenda ")], 1)])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "row no-gutters"
  }, [_c('div', {
    staticClass: "\n        col\n        app-calendar-sidebar\n        flex-grow-0\n        overflow-hidden\n        d-flex\n        flex-column\n      ",
    class: {
      show: _vm.isCalendarOverlaySidebarActive
    }
  }, [_c('calendar-sidebar', {
    attrs: {
      "is-event-handler-sidebar-active": _vm.isEventHandlerSidebarActive
    },
    on: {
      "update:isEventHandlerSidebarActive": function updateIsEventHandlerSidebarActive($event) {
        _vm.isEventHandlerSidebarActive = $event;
      },
      "update:is-event-handler-sidebar-active": function updateIsEventHandlerSidebarActive($event) {
        _vm.isEventHandlerSidebarActive = $event;
      }
    }
  })], 1), _c('div', {
    staticClass: "col position-relative"
  }, [_c('div', {
    staticClass: "card shadow-none border-0 mb-0 rounded-0"
  }, [_c('div', {
    staticClass: "card-body pb-0"
  }, [_c('full-calendar', {
    ref: "refCalendar",
    staticClass: "full-calendar",
    attrs: {
      "options": _vm.calendarOptions
    },
    scopedSlots: _vm._u([{
      key: "eventContent",
      fn: function fn(arg) {
        return [_c('div', {
          class: 'col-12 text-white bg-' + arg.backgroundColor,
          attrs: {
            "title": arg.timeText + ' ' + arg.event.title
          }
        }, [_c('strong', [_vm._v(_vm._s(arg.timeText))]), _c('b-dropdown', {
          staticClass: "pb-0",
          staticStyle: {
            "width": "100%",
            "position": "absolute",
            "left": "0"
          },
          attrs: {
            "dropright": "",
            "variant": "link",
            "no-caret": ""
          }
        }, [_c('b-dropdown-item', {
          attrs: {
            "to": 'consulta/' + arg.event.id
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ClipboardIcon"
          }
        }), _vm._v("Ir para Consulta ")], 1)], 1)], 1)];
      }
    }])
  })], 1)])]), _c('div', {
    staticClass: "body-content-overlay",
    class: {
      show: _vm.isCalendarOverlaySidebarActive
    },
    on: {
      "click": function click($event) {
        _vm.isCalendarOverlaySidebarActive = false;
      }
    }
  }), _c('calendar-event-handler', {
    attrs: {
      "event": _vm.event,
      "clear-event-data": _vm.clearEventData
    },
    on: {
      "remove-event": _vm.removeEvent,
      "add-event": _vm.addEvent,
      "update-event": _vm.updateEvent
    },
    model: {
      value: _vm.isEventHandlerSidebarActive,
      callback: function callback($$v) {
        _vm.isEventHandlerSidebarActive = $$v;
      },
      expression: "isEventHandlerSidebarActive"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }